<style>
.big-icon {
  font-size: 22px;
}

.big-icon:hover,
.dropdownOption:hover,
.arrow-btn:hover {
  cursor: pointer;
}

.dropdownOption {
  font-size: 12px;
}


</style>

<template>
  <div id="app">
  <router-view></router-view>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Navbar from './components/NavBar.vue'
import Sidebar from './components/SideBar.vue'
import RightSidebar from './components/RightSideBar.vue'
export default {
  /* eslint-disable */
  name: 'app',
  components: {
    Navbar,
    Sidebar,
    RightSidebar
  },
  computed: {
    ...mapState(['isDarkMode']),
  },
  data() {
    return {
      loginBody: {
        username: null,
        password: null
      },
      message: null,
      usernameInserted: false,

      totalExpiresToday: 0,
    }
  },

  methods: {
    authenticate() {
      if (this.loginBody.username == "aaadmin") return
      this.$loading(true);
      this.$Axios.post(`${this.$hostname}/api/users/authenticate?username=${this.loginBody.username}&password=${this.loginBody.password}`)
        .then((res) => {
          if (res.status == 200) {
            if (!res.data.isActive) {
              this.$toast.open({
                message: this.$t("messages.userNotActive"),
                type: 'error',
              });
              this.$loading(false);
              return
            }
            this.$session.start();
            this.$session.set("id", res.data.id);
            this.$session.set("username", res.data.username);
            this.$session.set("fullName", res.data.fullName);
            this.$session.set("phoneNumber", res.data.phoneNumber);
            this.$session.set("role", res.data.role);
            this.$session.set("token", res.data.token);
            this.$session.set("tenantName", res.data.tenantName);
            this.$store.commit("setLoggedUser", {
              id: res.data.id,
              username: res.data.username,
              role: res.data.role,
              token: res.data.token,
            });
            this.$toast.open({
              message: this.$t("messages.loginedSuccessfully"),
              type: 'success',
            });
            if (res.data.role == "RootAdmin" || res.data.role == "Admin") {
              this.$router.push("/");
            } else {
              this.$router.push("/Client/Dashboard");
            }
          } else {
            this.$toast.open({
              message: this.$t("messages.credentialError"),
              type: 'error',
            });
          }
          this.$loading(false);
        })
        .catch((err) => {
          this.message = this.$t("messages.internetError")
          if (err.response) {
            this.message = err.response.data.toString()
          }
          this.$toast.open({
            message: this.message,
            type: 'error',
          });
          this.$loading(false);
        });
    },


  },
  mounted() {
    if (this.$route.name == 'login') {
      setTimeout(() => {
        if (parseInt(new URLSearchParams(window.location.search).get("username"))) {
          this.loginBody.username = parseInt(
            new URLSearchParams(window.location.search).get("username")
          );
          this.usernameInserted = true;
        }
      }, 500);
      return
    }


  }
}
</script>


