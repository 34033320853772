<style scoped>
.remove-top-margin {
    margin-top: -15px;
}

.dataCol {
    border: 1px solid black !important;
}

.spinner-border {
    width: 15px !important;
    height: 15px !important;
    padding: 0%;
}
</style>

<template>
    <div class="container">
        <div class="row mt-3" v-if="user" dir="rtl">
            <div class="col col-lg-4 col-md-4 col-12 mt-2">
                <div class="card rounded-2 shadow-sm" style="height: 100%"
                    :class="$store.state.isDarkMode ? 'card-dark-mode' : 'card-light-mode'">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <h6 class="fw-bold">
                                {{
            isWakel
                ? $t("customersTable.name")
                : isZabwn
                    ? $t("customersTable.zabwn")
                    : $t("customersTable.sairafa")
        }}:
                            </h6>
                            <h6 class="mb-2 text-secondary">
                                {{ user.fullName }}
                            </h6>
                        </div>
                        <div class="d-flex justify-content-between mt-3">
                            <h6 class="fw-bold">{{ $t("customersTable.phone") }}:</h6>
                            <h6 class="text-secondary">
                                {{ user.phoneNumber }}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col col-lg-4 col-md-4 col-12 mt-2">
                <div class="card rounded-2 shadow-sm"
                    :class="$store.state.isDarkMode ? 'card-dark-mode' : 'card-light-mode-profile'">
                    <div class="card-body">
                        <h6 class="fw-bold">
                            {{ $t("customersTable.amount") }}
                        </h6>
                        <div class="row">
                            <div class="col-10" style="font-size: 30px">
                                {{
            (user.totalDebtDinnar - user.totalPaidDinnar).toLocaleString("en-US")
        }}
                                <span>{{ $t("currency.main") }}</span>
                            </div>
                            <div class="col-2">
                                <i class="mdi mdi-cash-multiple" style="font-size: 30px"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col col-lg-4 col-md-4 col-12 mt-2">
                <div class="card rounded-2 shadow-sm"
                    :class="$store.state.isDarkMode ? 'card-dark-mode' : 'card-light-mode-profile'">
                    <div class="card-body">
                        <h6 class="fw-bold">
                            {{ $t("customersTable.amountDollar") }}
                        </h6>
                        <div class="row">
                            <div class="col-10" style="font-size: 30px">
                                {{
            (user.totalDebtDollar - user.totalPaidDollar).toLocaleString("en-US")
        }}
                                <span>{{ $t("currency.secondary") }}</span>
                            </div>
                            <div class="col-2">
                                <i class="mdi mdi-cash-multiple" style="font-size: 30px"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card rounded-2 mt-3 shadow-sm" dir="rtl">
            <div class="card-body">

                <div class="table-responsive">
                    <table class="table table-sm table-striped table-hover text-center"
                        v-if="transactions && transactions.items.length > 0">
                        <thead class="bg-dark text-light">
                            <tr>
                                <th>#</th>
                                <th>
                                    {{ $t("addUserTransaction.amount") }}
                                </th>
                                <th>
                                    {{ $t("addUserTransaction.paymentType") }}
                                </th>

                                <th>
                                    {{ $t("addUserTransaction.date") }}
                                </th>
                                <th>
                                    {{ $t("addUserTransaction.note") }}
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(transaction, index) in transactions.items" :key="transaction.id">
                                <td class="py-1">
                                    {{ ++index }}
                                </td>
                                <td>
                                    {{ transaction.amount.toLocaleString("en-US") }}
                                    <span>{{ getCurrencyText(transaction.currencyType) }} </span>
                                    <span class="d-block my-2"
                                        v-if="transaction.currencyType == 3 || transaction.currencyType == 4">{{
            $t("addUserTransaction.convertedAmount") }}:
                                        {{ transaction.convertedAmount.toLocaleString() }}</span>
                                </td>
                                <td>
                                    <div :class="getPaymentTextClass(transaction.paymentType)" class="badge me-3">
                                        {{
            isWakel
                ? getPaymentTextWakel(transaction.paymentType)
                : isZabwn
                    ? getPaymentTextZabwn(transaction.paymentType)
                                        : getPaymentTextSairafa(transaction.paymentType)
                                        }}
                                    </div>
                                </td>
                                <td>
                                    {{ cleanDate(transaction.date) }}
                                </td>

                                <td>
                                    {{ transaction.note }}
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <h4 v-else class="text-center text-secondary">{{ $t("noTransaction") }}</h4>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "DesktopClientDashboard",
    props: ["transactions", "user"],
    data() {
        return {
            isWakel: undefined,
            isZabwn: undefined,
            isSairafa: undefined,
        }
    },

    mounted() {
        setTimeout(() => {
            this.isWakel = this.user.role == "3";
            this.isZabwn = this.user.role == "5";
            this.isSairafa = this.user.role == "6";
        }, 250);
    },







};
</script>
