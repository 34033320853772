<style scoped>
.top-header {
  width: 100%;
  height: 20vh;
  background-color: #57549e;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  z-index: 10000;

}

.debt-card-info {
  position: absolute;
  bottom: -50px;
  width: 80%;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}

.slogon {
  position: absolute;
  top: 0;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

html, body {
  overflow-y: hidden !important;
}
</style>

<template>
  <div style="height: 100vh; overflow-y: scroll;" dir="rtl">
    <div class="top-header">
      <div class="d-flex justify-content-between slogon">
        <p class="mt-4 text-light">{{ $t("welcome") }}</p>
        <img width="80px" src="../assets/logo.png" />
      </div>
      <div class="card rounded-2 debt-card-info shadow p-3" v-if="userInfo">
        <h1 style="font-size: 30px" class="text-center text-dark mt-3">
          {{ (userInfo.totalDebtDinnar - userInfo.totalPaidDinnar).toLocaleString("en-US") }}
          <span>{{ $t("currency.main") }} </span>
        </h1>
        <h1 style="font-size: 18px" class="text-center text-dark">
          {{ (userInfo.totalDebtDollar - userInfo.totalPaidDollar).toLocaleString("en-US") }}
          <span>{{ $t("currency.secondary") }} </span>
        </h1>
        <div class="info d-flex justify-content-between mt-2 text-secondary">
          <h5>{{ userInfo.fullName }}</h5>
          <p>{{ userInfo.phoneNumber}}</p>
        </div>
      </div>
    </div>

    <div class="transactions-view p-3" style="margin-top: 220px;" v-if="transactions">
      <div
        class="card rounded-2 shadow-sm mt-2"
        style="border: 1px solid #73726f"
        v-for="transaction in transactions.items"
        :key="transaction.id"
      >
      <div class="card-header text-center" v-if="transaction.note">
        <small class="text-secondary text-center">{{ transaction.note }}</small>
      </div>
        <div class="body p-3 d-flex justify-content-between">
          <div>
            <div class="d-flex">
              <p>
                <span
                  style="display: inline"
                  :class="getPaymentTextClass(transaction.paymentType)"
                  class="badge me-3"
                >
                  {{transactions.role == 3? getPaymentTextWakel(transaction.paymentType) : getPaymentTextZabwn(transaction.paymentType) }}</span
                >
              </p>
              <p class="text-dark mx-2 text-bold">
                {{ transaction.amount.toLocaleString() }}
                <span>{{ getCurrencyText(transaction.currencyType) }} </span>
              </p>
            </div>
           
          </div>
          <p class="text-secondary">{{ formatDatee(transaction.creationDate) }}</p>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MobileClientDashboard",
  props: ["transactions", "userInfo"],
  
  
};
</script>
