<template>
  <div>
    <div class="screens" v-if="linkValid">
      <MobileClinetDashboard :transactions="transactions" :user-info="userInfo" v-if="mobileScreen" />
      <DesktopClientDashboard :transactions="transactions" :user="userInfo" v-else />
    </div>
    <div style="width: 100%; height:100vh" v-else class="d-flex justify-content-center align-items-center flex-column">
      <img src="https://delishop.asia/static/media/expired.0959a069.gif" alt="" :class="mobileScreen ? 'w-50' : 'w-25'">
      <h4 class="text-bold">{{ $t("messages.linkExpired") }}</h4>
    </div>
  </div>
</template>
<script>
import MobileClinetDashboard from "@/components/MobileClinetDashboard.vue"
import DesktopClientDashboard from "@/components/DesktopClientDashboard.vue"
export default {
  name: "ClientDashboard",
  components: {
    MobileClinetDashboard,
    DesktopClientDashboard
  },
  created() {
    const breakpoint = 768;
    this.mobileScreen = window.innerWidth < breakpoint;
  },
  async mounted() {
    await this.getTransactions()
  },
  data() {
    return {
      transactions: null,
      userInfo: null,

      linkValid: true,
      mobileScreen: false,
    };
  },
  methods: {
    async getTransactions() {
      this.$loading(true);
      const linkId = this.$route.params.id;

      await this.$Axios
        .get(
          `${this.$hostname}/api/Links/specific/identity/?userId=${linkId}`,
        )
        .then((res) => {
          this.transactions = res.data.transactions;
          this.userInfo = res.data.userInfo

          this.$loading(false);
        })
        .catch((err) => {
          this.$loading(false);
          this.$toast.open({
            message: err.toString(),
            type: "error",
          });
          this.linkValid = false
        });
    },
  },
};
</script>
