import Vue from 'vue'
import VueRouter from 'vue-router'



import ClientDashboardView from '../views/ClientDashboard/ClientDashboardView.vue'

//import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/:id',
    name: 'ClientDashboard',
    component: ClientDashboardView,
  },
  

  { path: '/404', redirect: '/login' },
  { path: '*', redirect: '/404' },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) =>
// {
//   if (!store.state.loggedUser)
//   {
//     // You can use store variable here to access globalError or commit mutation 
//     next("/Login")
//   } else if (store.state.loggedUser)
//   {

//     next();
//   }
//   else
//   {
//     next()
//   }
// })

export default router
